import { Email } from '@mui/icons-material';
import { Button, Alert, Stack, Box, Grid, Typography } from '@mui/joy';
import { useRouteError } from 'react-router-dom';

const ErrorBoundary = () => {
  const error = useRouteError();
  return (
    <Grid container justifyContent="center" sx={{ mt: 9 }}>
      <Grid xs={9} sm={6} lg={3}>
        <Stack spacing={3}>
          <Typography level="title-lg">
            🤦‍♀️ Oops, something went wrong..
          </Typography>
          <Alert color="danger">The error: {error.message}</Alert>
          <Typography level="body-lg">
            Not to worry though, just click the link below and we'll sort it out
            🤓
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button
              component="a"
              variant="plain"
              href={`mailto:support@golightlyplus.com?subject=ELFS%20app%20error&body=The%20error:%20${encodeURIComponent(error.message)}`}
              startDecorator={<Email />}
            >
              support@golightlyplus.com
            </Button>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ErrorBoundary;
