import { BugReport } from '@mui/icons-material';
import { Container, Grid, Button, Alert } from '@mui/joy';
import {
  getFirestore,
  collection,
  query,
  getDocs,
  deleteDoc,
  updateDoc,
  doc,
  deleteField
} from 'firebase/firestore';
import { useUser } from '../context/user';

const Debug = () => {
  const { user } = useUser();

  const clearSurveyResponses = async () => {
    if (!user?.id) return;

    // delete all survey responses
    const querySnapshot = await getDocs(
      query(collection(getFirestore(), `participants/${user.id}/responses`))
    );
    const deletePromises = querySnapshot.docs.map(docSnapshot =>
      deleteDoc(docSnapshot.ref)
    );
    await Promise.all(deletePromises);

    // delete the netResponses value for the user
    await updateDoc(doc(getFirestore(), `participants/${user.id}`), {
      netResponses: {
        dob: user.dob,
        totalSurveys: 0
      },
      followUp: false,
      followUpReason: '',
      status: 'account created',
      studyStatus: deleteField()
    });

    alert('All survey responses cleared.');
  };

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid xs={12}>
          <Alert color="warning" startDecorator={<BugReport />} sx={{ my: 3 }}>
            For development and debugging
          </Alert>
          <Button onClick={clearSurveyResponses}>
            Clear all survey responses
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Debug;
