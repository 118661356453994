import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate
} from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import englishTranslations from './locales/english.json';
import vietnameseTranslations from './locales/vietnamese.json';
import hebrewTranslations from './locales/hebrew.json';
import './modules/firebase';
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import ThankYou from './components/ThankYou';
import Debug from './components/Debug';
import Settings from './components/Settings';
import ErrorBoundary from './components/ErrorBoundary';
import { UserProvider, useUser } from './context/user';
import Contact from './components/Contact';
import SurveyHistory from './components/SurveyHistory';
import About from './components/About';

i18n.use(initReactI18next).init({
  resources: {
    english: { translation: englishTranslations },
    vietnamese: { translation: vietnameseTranslations },
    hebrew: { translation: hebrewTranslations }
  },
  lng: 'english', // Set default language
  fallbackLng: 'english',
  interpolation: {
    escapeValue: false
  }
});

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin]
});

// Create ltr cache
const cacheLtr = createCache({
  key: 'muiltr'
});

const designTokens = {
  // Main colors
  primary: '#E46399', // China Pink
  secondary: '#C398C5', // Lilac
  tertiary: '#DF9843', // Carrot Orange
  quaternary: '#2A2E45', // Space Cadet

  // Background colors
  background: {
    light: '#E7EDF7', // Alice Blue
    dark: '#2A2E45' // Space Cadet
  },

  // Text colors
  text: {
    light: '#2A2E45', // Space Cadet
    dark: '#FFFFFF', // White
    lightSecondary: '#5C5C5C', // Davys Grey
    darkSecondary: '#AEADAE' // Silver Chalice
  },

  // Button colors
  button: {
    default: '#E46399', // China Pink
    selected: '#E46399', // China Pink
    unselected: '#F2C9DF', // Orchid Pink (lighter version of China Pink)
    hover: '#C398C5', // Lilac
    active: '#DF9843' // Carrot Orange
  },

  // Additional colors
  white: '#FFFFFF',
  black: '#000000',
  orchidPink: '#F2C9DF',
  champagnePink: '#E5D4CE',
  lavenderWeb: '#D9E0F2',

  // Grayscale
  gray: {
    light: '#AEADAE', // Silver Chalice
    medium: '#939393', // Spanish Grey
    dark: '#5C5C5C' // Davys Grey
  }
};

const createPalette = mode => ({
  primary: {
    main: designTokens.primary,
    solidBg: designTokens.button.default,
    solidHoverBg: designTokens.button.hover,
    solidActiveBg: designTokens.button.active,
    solidColor: designTokens.white,
    softBg: designTokens.button.unselected,
    softHoverBg: designTokens.button.hover,
    softActiveBg: designTokens.button.selected,
    softColor: designTokens.text.light,
    softActiveColor: designTokens.white,
    plainColor: designTokens.primary,
    plainHoverBg: designTokens.button.unselected,
    plainActiveBg: designTokens.button.hover
  },
  secondary: {
    main: designTokens.secondary
  },
  success: {
    main: designTokens.tertiary, // Carrot Orange
    solidBg: designTokens.tertiary, // Carrot Orange for solid background
    solidHoverBg: designTokens.button.hover, // Hover color
    solidActiveBg: designTokens.button.active, // Active color
    solidColor: designTokens.white, // Text color for solid variant
    softBg: designTokens.champagnePink,
    softColor: designTokens.quaternary
  },
  warning: {
    main: designTokens.tertiary, // Carrot Orange
    softBg: designTokens.orchidPink,
    softColor: designTokens.quaternary
  },
  error: {
    main: designTokens.primary, // China Pink
    softBg: designTokens.orchidPink,
    softColor: designTokens.quaternary
  },
  info: {
    main: designTokens.secondary, // Lilac
    softBg: designTokens.lavenderWeb,
    softColor: designTokens.quaternary,
    plainColor: designTokens.secondary,
    plainHoverBg: designTokens.lavenderWeb,
    plainActiveBg: designTokens.secondary
  },
  background: {
    body:
      mode === 'light'
        ? designTokens.background.light
        : designTokens.background.dark,
    surface: mode === 'light' ? designTokens.white : designTokens.quaternary,
    level1: mode === 'light' ? designTokens.white : designTokens.quaternary,
    level2:
      mode === 'light'
        ? designTokens.background.light
        : designTokens.background.dark,
    popup: mode === 'light' ? designTokens.white : designTokens.quaternary
  },
  text: {
    primary:
      mode === 'light' ? designTokens.text.light : designTokens.text.dark,
    secondary:
      mode === 'light'
        ? designTokens.text.lightSecondary
        : designTokens.text.darkSecondary
  },
  divider: mode === 'light' ? designTokens.gray.light : designTokens.gray.dark,
  mode: mode
});

const baseTheme = extendTheme({
  cssVarPrefix: 'elfs',
  colorSchemes: {
    light: {
      palette: createPalette('light')
    },
    dark: {
      palette: createPalette('dark')
    }
  }
});

const App = () => {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
};

const ltrTheme = extendTheme({
  ...baseTheme,
  direction: 'ltr'
});

const rtlTheme = extendTheme({
  ...baseTheme,
  direction: 'rtl'
});

const AppContent = () => {
  const { user } = useUser();
  const isRtl = user?.language === 'hebrew';

  useEffect(() => {
    document.dir = isRtl ? 'rtl' : 'ltr';
  }, [isRtl]);

  return (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <CssVarsProvider theme={isRtl ? rtlTheme : ltrTheme} defaultMode="system">
        <CssBaseline />
        <Outlet />
      </CssVarsProvider>
    </CacheProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <LandingPage />
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          { index: true, element: <Navigate to="home" replace /> },
          {
            path: 'home',
            element: <Home />
          },
          {
            path: 'history',
            element: <SurveyHistory />
          },
          {
            path: 'about',
            element: <About />
          },
          {
            path: 'settings',
            element: <Settings />
          },
          {
            path: 'debug',
            element: <Debug />
          },
          {
            path: 'thank-you',
            element: <ThankYou />
          },
          {
            path: 'contact',
            element: <Contact />
          }
        ]
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
